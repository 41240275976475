@import '../../../styles/gifts/sass/basics';
@value formatted-text from '../../../components/01_atoms/FormattedText/index.module.scss';

.bb-faq {
  background-color: $biscuit-400;
  margin: 64px auto;
  &__background {
    background-color: $biscuit-400;
    padding: 64px 0;
    margin: 0 auto;
  }

  &__heading {
    margin-bottom: 64px;
    text-align: center;
    color: $turquoise-1200;
    font-weight: bold;
    font-size: 28px;
    line-height: $line-height-normal;
    max-width: 460px;
    margin-left: auto;
    margin-right: auto;
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 900px;
    margin: 0 auto 8px;
  }
  &__item {
    background-color: $white;
  }

  &__item-button {
    padding: 28px;
    font-size: 18px;
    color: $gray-1200;
    font-weight: bold;
    display: flex;
    gap: 14px;
    justify-content: space-between;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
  }

  &__item-icon {
    width: 24px;
    flex-shrink: 0;
    color: $turquoise-800;
  }

  &__item-description {
    line-height: $line-height-large;
    padding: 0 28px 28px;
    font-size: 18px;
    color: $gray-1000;
  }

  &__link {
    max-width: 900px;
    text-align: right;
    margin: 64px auto 8px;
    a {
      border-bottom: 2px solid $turquoise-1000;
      color: $turquoise-1000;
      text-decoration: none;

      svg {
        margin-left: 4px;
      }
    }
  }

  @include media-breakpoint-up(md) {
    margin: 80px auto;
    background-color: $white;
    &__background {
      padding: 64px 50px;
    }
  }

  @include media-breakpoint-up(lg) {
    &__background {
      padding: 104px 108px;
    }
    &__items {
      gap: 12px;
    }
    &__link {
      margin-top: 56px;
    }
    &__item-button {
      padding: 28px 28px 28px 48px;
    }
    &__item-description {
      padding: 0 48px 28px;
    }
  }

  [data-component="formatted-text"] {
    > *:first-child {
      margin-top: 0;
    }
    > *:last-child {
      margin-bottom: 0;
    }
  }

  @include media-breakpoint-up(xl) {
    &__background {
      padding: 104px 92px;
    }
    &__heading {
      font-size: 36px;
    }
    &__item-description,
    &__item-button {
      font-size: 22px;
    }
    &__link {
      margin-top: 48px;
    }
  }
  @include media-breakpoint-up(xxl) {
    background-color: $biscuit-400;
    margin: 80px 80px;
    max-width: calc(100vw - 160px);
    &__background {
      padding: 104px 117px;
    }
    &__link {
      margin-top: 32px;
    }
  }
}